<template>
    <RecipesSection/>
</template>
<script>
import RecipesSection from '@/components/recipes/RecipesSection.vue';

export default {
    name: 'RecipesPage',
    components: { 
        RecipesSection
    },
}
</script>
<style>
    
</style>