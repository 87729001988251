<template>
    <div class="container py-4">
        <div class="p-4 description-main fw-bold shadow rounded ">
            <a href="https://youtu.be/3IX6jRta5Po" target="_blank"><img style="float: right;" :src="require('../assets/pjes_mirror.png')" alt="pjes" height="140"></a>
            <h2>{{$t("components.about_section.places_title")}}</h2>
            <p>
            {{$t("components.about_section.places_text")}}
            </p>
            <h2>{{$t("components.about_section.whois_title")}}</h2>
            <p>
            {{$t("components.about_section.whois_text")}}
            </p>
            <h2>{{$t("components.about_section.travels_title")}}</h2>
            <p>
            {{$t("components.about_section.travels_text_1")}}
            <br />
            <br />
            {{$t("components.about_section.travels_text_2")}}
            <br />
            <br />
            {{$t("components.about_section.travels_text_3")}} <a href="http://www.maklowicz.pl/" target="_blank">http://www.maklowicz.pl/</a>.
            </p>
            <h2>{{$t("components.about_section.recipes_title")}}</h2>
            <p>
            {{$t("components.about_section.recipes_text")}}
            </p>
            <h2>{{$t("components.about_section.freetime_title")}}</h2>
            <p>
            {{$t("components.about_section.freetime_text_1")}}
            <br />
            <br />
            {{$t("components.about_section.freetime_text_2")}}
            <br />
            <br />
            {{$t("components.about_section.freetime_text_3")}}
            <br />
            <br />
            {{$t("components.about_section.freetime_text_4")}} <a href="mailto:mapa.maklowicza@gmail.com">mapa.maklowicza@gmail.com</a>.
            <br />
            <br />
            {{$t("components.about_section.freetime_text_5")}}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutSection'
}
</script>

<style>
.description-main {
    background-color: rgba(248, 249, 250, 0.6)
    /* #f8f9fa;
    opacity: 0.5; */
}
</style>