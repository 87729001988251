import { createWebHistory, createRouter } from 'vue-router'
import i18n from "../i18n";
import AboutPage from '@/views/AboutPage.vue'
import MapPage from '@/views/MapPage.vue'
import MainVue from '@/views/MainVue.vue'
import ConsentPage from '@/views/ConsentPage.vue'
import RecipesPage from '@/views/RecipesPage.vue'
import RecipePage from '@/views/RecipePage.vue'

const locale = i18n.global.locale;

const routes = [
    {
      path: '/',
      redirect: `/${locale}/about`  //AB test
    },
    {
      path: "/:lang(en|pl)",
      redirect: to => "/" + to.params.lang + "/about"
    },
    {
      path: '/:lang(en|pl)',
      component: MainVue,
      children: [
        {
          path: "map/:id?",
          name: "map",
          component: MapPage,
        },
        {
          path: "about",
          name: "about",
          component: AboutPage,
        },
        {
          path: "recipes",
          name: "recipes",
          component: RecipesPage
        },
        {
          path: "recipe/:id",
          name: "recipe",
          component: RecipePage
        }
      ]
    },
    {
      path: "/consent",
      name: "consent",
      component: ConsentPage,
    },
    {
      path: "/:catchAll(.*)",
      name: "notfound",
      redirect: `/${locale}/about`}
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  let language = to.params.lang;
  if (!language || (language !== 'en' && language !== 'pl')) {
    language = 'pl';
  }
  if(i18n.global.locale !== language) {
    i18n.global.locale = language;
  } 
});

  export default router;