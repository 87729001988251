<template>
    <div id="map" ref="googleMap" >
        <noscript>This page does not work without JavaScript</noscript>
    </div>
</template>

<script>
import { usePlacesListStore } from '@/stores/PlacesListStore';
import { storeToRefs } from 'pinia';

export default {
    name: 'PlacesMap',
    setup(){
        const { mountMap } = usePlacesListStore();

        const { placesList } = storeToRefs(usePlacesListStore());

        return {
            mountMap,
            placesList
        }
    },
    mounted (){
        this.mountMap(document.getElementById('map'));
    },
    watch:{
        placesList () {
            this.mountMap(document.getElementById('map'));
        }
    }
}
</script>

<style scoped>
    #map {
        height: 100%;
    }
</style>