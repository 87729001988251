<template>
  <div class="container">
    <template v-for="(locationType, index) in filters" :key="index">
        <input type="checkbox" @input="() => this.callback(index)"  class="btn-check" :id="`btn-check-outlined-${index}`" :checked="this.filters[index]" autocomplete="off">
        <label class="btn btn-outline-primary m-1 " :for="`btn-check-outlined-${index}`" >{{$t(`components.filters_selector.${index}`)}}</label> 
    </template>
  </div>
</template>

<script>
import { usePlacesListStore } from '@/stores/PlacesListStore';
import { storeToRefs } from 'pinia';

export default {
    name: "FilterSelector",
    setup () {
      const { updateBound, unhighlight } = usePlacesListStore();
      const { filters } = storeToRefs(usePlacesListStore());

      return { 
          filters,
          updateBound,
          unhighlight
        }
    },
    methods:{
      callback(value) {
        this.unhighlight();
        this.filters[value] = !this.filters[value];
        this.updateBound();
      }
    }
}
</script>

<style scoped>
.btn-outline-primary:hover {
  color: #0d6efd;
  background-color: unset;
  border-color: #0d6efd;
}

</style>