export default {
  "navbar": {
    "brand": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa Podróżnika"])}
    },
    "locations": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nas"])},
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
      "recipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepisy"])}
    }
  },
  "components": {
    "places_list": {
      "locations_near_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź lokacje w pobliżu ciebie"])},
      "input_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
      "input_addres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz twój adres lub kod"])},
      "all_locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie lokacje"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])}
    },
    "places_item": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])}
    },
    "filters_selector": {
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedzenie"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noclegi"])},
      "drink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkohole"])},
      "castle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabytki"])},
      "museum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzea"])},
      "tea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kawa/Herbata"])}
    },
    "about_section": {
      "places_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsca Roberta Makłowicza"])},
      "places_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To tutaj odnajdziesz wszystkie lokacje, które Robert Makłowicz odwiedził podczas swoich wędrówek; niezliczone krainy geograficzne, restauracje, lokalne atrakcje oraz ciekawe zabytki."])},
      "whois_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kim jest Robert Makłowicz?"])},
      "whois_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robert Makłowicz to znany na całą Polskę krytyk kulinarny, dziennikarz, pisarz, podróżnik. Lata spędzone w drodze, podczas realizowania popularnego programu telewizyjnego, umożliwiły mu zbudowanie ogromnej wiedzy na temat światowej kultury, historii i tradycji. Podróże pozwoliły mu również wykształcić instynkt, który zawsze kieruje go tam, gdzie świat jest rajski, a jedzenie pełne smaku. Dzięki swojemu doświadczeniu Pan Robert wypuścił spod swojego pióra wiele książek kulinarno-historycznych takich jak „C.K. Kuchnia\", „Cafe Museum\" i „Dalmacja\"."])},
      "travels_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podróże"])},
      "travels_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecnie Pan Robert prowadzi na swoim kanale, w serwisie YouTube, serię podróżniczo-kulinarną, w której przedstawia interesujące miejsca w Polsce, oraz poza jej granicami, ze szczególnym uwzględnieniem lokalnej kuchni. Odcinki pełne są pięknych lokacji i ekscytujących historii, a także pysznych dań, gotowanych również przez samego prowadzącego. Robertowi Makłowiczowi udało się odwiedzić już między innymi; Chorwację, ze szczególnym uwzględnieniem Dalmacji oraz Baranji, Wielkopolskę, Beskid, Mazowsze, Pomorze, Podlasie, Szwecję, Austrię, południowy Tyrol oraz Bośnię. Przybliża on również swoim widzom kultury z różnych zakątków świata na przykład Kirgistanu, tatarskich stepów czy Japonii."])},
      "travels_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponadto, gości on regularnie w swoim programie ciekawych ludzi, zajmujących się gastronomią, turystyką czy rekreacją oraz tych, którzy znacząco przyczyniają się do utrzymywania i rozwoju dziedzictwa kulturowego odwiedzanych przez niego miejsc. W swoich materiałach Pan Robert prezentuje również lokalne atrakcje takie jak; nurkowanie w Adriatyku, czy jazda psim zaprzęgiem po zamarzniętym jeziorze."])},
      "travels_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji o Panu Robercie oraz jego przepisy można znaleźć na stronie "])},
      "freetime_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplanuj wolny czas"])},
      "freetime_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzięki tej stronie możecie Państwo zaplanować własną podróż, zarówno wakacyjną jak i wypad na weekend, tak, aby była ona pełna interesujących atrakcji, pięknych krajobrazów i najlepszych restauracji. Mapa pozwala również sprawdzić, czy Pan Robert nie odkrył przypadkiem jakiejś lokalnej perełki, w pobliżu określonej lokalizacji. Szczegóły dla każdego skatalogowanego miejsca dają możliwość przejścia w łatwy sposób, bezpośrednio do materiałów na YouTube, w celu dokładniejszego zapoznania się z daną atrakcją."])},
      "freetime_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robert Makłowicz odwiedza w swoim programie wiele różnych lokacji, z których nie każda jest otwarta dla przeciętnego gościa. Często bywa on również w lokacjach, których nie da się zawęzić do pojedynczego punktu na mapie. Tak bywa na przykład w przypadku miast, po których gospodarz programu często przechadza się oferując ciekawe informacje na temat pobliskich atrakcji. Dlatego też, wśród zebranych na mapie punktów może brakować specyficznych obiektów lub są one oznaczone zbiorczo, na przykład jako całe miasto."])},
      "freetime_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta strona jest darmowa i każdy może z niej korzystać jak chce. Środki na pokrycie kosztów generowane są za pomocą umieszczonych reklam dlatego prosimy o wyłączenie rozwiązań typu ad-block. Staramy się by nowe zaprezentowane w programach lokacje pojawiały się regularnie jednak niekiedy mogą one być dodawane z opóźnieniem."])},
      "freetime_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeżeli strona Ci się podoba nie zapomnij udostępnić jej znajomym. Komentarze do strony mile widziane pod adresem "])},
      "freetime_text_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podróżowanie to sztuka, którą Pan Robert zgłębił w każdym detalu, więc uczmy się od niego wszyscy. Ajde!"])},
      "recipes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepisy"])},
      "recipes_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pan Robert w niemal każdym odcinku swojego programu prezentuje również interesujące dania, często związane z krajobrazem gastronomicznym odwiedzanego miejsca. Na naszej stronie znajdziesz katalog przepisów Roberta Makłowicza wraz z listą składników i dokładnym odnośnikiem do instrukcji ich przygotowania w serwisie youtube. Przygotowanie dania z odległego zakątka świata pozwoli Ci odbyć podróż bez wychodzenia z domu."])}
    },
    "recipes_list": {
      "input_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])}
    },
    "recipe": {
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Składniki"])},
      "recipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepis"])}
    },
    "adblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta strona jest darmowa, a generowane koszty są pokrywane z reklam. Prosimy o wyłączenie rozwiązania adblock."])},
    "share_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa Makłowicza"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa podróżnika pozwala odnaleźć wszystkie miejsca odwiedzone przez Roberta Makłowicza."])}
    }
  }
}