<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light py-0 header-top fw-bold">
    <div class="container-fluid">
      <a
        class="navbar-brand py-0 custom-font"
        href="https://youtu.be/ioiu6L3SCq8"
        target="_blank"
      >
        <img
          class="py-0 my-0"
          :src="require('../assets/McMaklowicz.png')"
          alt="Robert Makłowicz"
          height="45"
        />
        {{ $t("navbar.brand.name") }}
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse custom-font-small fw-bold mx-1"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link py-1"
              :class="{ active: $route.name == 'about' }"
              :to="'/' + $route.params.lang + '/about'"
              >{{ $t("navbar.locations.about") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link py-1"
              :class="{ active: $route.name == 'map' }"
              :to="'/' + $route.params.lang + '/map'"
              >{{ $t("navbar.locations.map") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link py-1"
              :class="{ active: $route.name == 'recipes' }"
              :to="'/' + $route.params.lang + '/recipes'"
              >{{ $t("navbar.locations.recipes") }}</router-link
            >
          </li>
          <li class="nav-item">
            <ShareSection :url="url" @share="share" />
          </li>
        </ul>
        <div class="d-flex py-1">
          <LangSelector />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LangSelector from "./LangSelector.vue";
import ShareSection from "./ShareSection.vue";
// import ReportingService from "@/services/ReportingService.js";

export default {
  name: "NavBar",
  components: { LangSelector, ShareSection },
  setup() {
    return {
      url: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    share() {
      // ReportingService.share();
    },
  },
};
</script>

<style scoped>
.custom-font {
  font-family: "allura";
  /* font-weight: bold; */
  font-size: 30px;
}

.custom-font-small {
  font-family: "allura";
  /* font-weight: bold; */
  font-size: 25px;
}

.header-top {
  z-index: 100;
}
</style>
