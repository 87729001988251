<template>
  <div class="height-100">
    <PlacesSection/>
  </div>
</template>

<script>
import PlacesSection from '@/components/PlacesSection.vue';
import { usePlacesListStore } from '@/stores/PlacesListStore';

export default {
  name: 'MapPage',
  components: { 
    PlacesSection
    },
  setup(){
    const placesListStore = usePlacesListStore();
    return {
          initLocation: placesListStore.initLocation
      }
  },
  beforeMount(){
    let id = this.$route.params.id;
    this.initLocation(id);
  }
}
</script>

<style>
.height-100{
  height: calc(100vh - 46px);
}
</style>