<template>
  <div class="map-container" >
      <div class="locations-panel-section p-0"><PlacesList /></div>
      <div class="map-section"><PlacesMap/></div>
  </div>
</template>

<script>
import PlacesList from './PlacesList.vue'
import PlacesMap from './PlacesMap.vue'
export default {
  name: 'PlacesSection',
  components: { 
    PlacesMap, 
    PlacesList 
    },
}
</script>

<style>

.map-container {
  width: 100%;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  position: absolute;
  height: calc(100vh - 46px);
}



.map-section {
  position: absolute;
  left: 30%;
  top: 0;
  right: 10%;
  bottom: 0;
}

.locations-panel-section {
  position: absolute;
  left: 10%;
  width: 20%;
  top: 0;
  bottom: 0;
  padding: 0.5em;
  box-sizing: border-box;
  background: white;
}

/* TODO change breakpoint */
@media only screen and (max-width: 992px) { 
  .map-section {
    left: 0;
    width: 100%;
    bottom: 50%;
  }

  .locations-panel-section {
    top: 50%;
    right: 0;
    left: 0;
    width: unset;
  }
}





</style>