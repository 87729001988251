import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
// import ReportingService from '@/services/ReportingService.js';
import VueSocialSharing from 'vue-social-sharing'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSquareFacebook, faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap"
import i18n from './i18n'



library.add([faSquareFacebook, faSquareTwitter]);

const pinia = createPinia();
const app = createApp(App).use(i18n).use(pinia).use(router).use(VueSocialSharing).component("font-awesome-icon", FontAwesomeIcon);


import ScriptX from 'vue-scriptx'
app.use(ScriptX)

// import Ads from 'vue-google-adsense'
// app.use(Ads.Adsense)
// app.use(Ads.AutoAdsense, { adClient: 'ca-pub-123456789', isNewAdsCode: true })

// ReportingService.visit();
app.mount('#app')
