<template>
    <div class="py-1">
        <button class="px-2">
            <ShareNetwork
                network="facebook"
                :url="url"
                :title="$t('components.share_section.title')"
                :description="$t('components.share_section.description')"
                @open="$emit('share')"
                >
                <font-awesome-icon class="align-middle fa-xl" icon="fa-brands fa-square-facebook " />
            </ShareNetwork>
        </button>
        <button class="px-2">
            <ShareNetwork
                network="twitter"
                :url="url"
                :title="$t('components.share_section.title')"
                :description="$t('components.share_section.description')"
                @open="$emit('share')"
                >
                <font-awesome-icon class="align-middle fa-xl" icon="fa-brands fa-square-twitter" />
            </ShareNetwork>
        </button>
    </div>
</template>

<script>
export default {
    name: "ShareSection",
    props: {
        url: String
    }
}
</script>

<style>

</style>