<template>
  <div id="page-container">
    <div id="content-wrap">
      <NavBar/>
      <router-view></router-view>
      <AdblockModal v-if="adBlock" @click="closeModal()"/>
    </div>
    <footer id="footer">
      <div class="muted text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
        <a href="/consent">Polityka prywatności</a>
      </div>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import AdblockModal from './components/AdblockModal.vue'
import { detectAnyAdblocker } from "vue-adblock-detector";
// import ReportingService from '@/services/ReportingService.js'

export default {
  name: 'App',
  components: {
    NavBar,
    AdblockModal
    
  },
  data(){
    return {
      adBlock: false
    }
  },
  methods: {
    closeModal(){
      this.adBlock = false;
    }
  },
  beforeMount(){
    document.title = 'Mapa Podróżnika';
    setTimeout(() => detectAnyAdblocker().then((detected) => {
        if(detected){
          this.adBlock = true;
        }
      }), 10 * 1000); //AB test
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100%;
}

@font-face {
  font-family: "allura";
  src: url('@/assets/fonts/Allura-Regular.ttf') format("truetype");
}

.top {
  z-index: 200
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 3rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;            /* Footer height */
}
</style>
