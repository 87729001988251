<template>
    <ConsentSection/>
</template>

<script>
import ConsentSection from '../components/ConsentSection.vue'

export default {
    name: 'ConsentPage',
    components: { ConsentSection },
}
</script>

<style>
</style>