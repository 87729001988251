<template>
    <div class="bg-light h-100 py-1 rounded custom-hover">
        <div class="row">
            <div class="text-center p-3">
                <img :src='"/recipes/"+recipe.img' class="rounded img-food" alt="image" style=""/>
            </div>
        </div>
        <div class="row text-center">
            <h5>
                {{ recipe.title }}
            </h5>
            <h6>
                {{ recipe.altTitle }}
            </h6>
        </div>
    </div>
</template>
<script>
export default {
    name: "RecipeListItem",
    props: {
        recipe: Object
    }
}
</script>
<style>

.img-food {
    transition: all .5s;
    width: 90%; 
    height: 200px;
    object-fit: cover;
}

div.custom-hover:hover{
    background-color: blanchedalmond !important;
    
}

div.custom-hover:hover img{
    transform: scale(1.05);
}

</style>