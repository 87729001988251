<template>
  <div id="locations-panel" class="p-0">
    <div v-if="!this.details" id="location-results-list" >
      <header>
          <h3 class="search-title">
          <img src="https://fonts.gstatic.com/s/i/googlematerialicons/place/v15/24px.svg" />
          {{$t("components.places_list.locations_near_you")}} 
          </h3>
          <div class="search-input">
          <input id="location-search-input" :placeholder="$t('components.places_list.input_addres')" ref="location-search-input" >
          <div id="search-overlay-search" class="search-input-overlay search" >
              <button id="location-search-button" ref="location-search-button" >
              <img class="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/search/v11/24px.svg"
                  alt="Search" />
              </button>
          </div>
          </div>
          <div class="search-input">
            <input v-model="search" @input="() => {this.unhighlight(); this.filterMapLocations();}" id="location-content-search-input" :placeholder="$t('components.places_list.input_search')">
          </div>
      </header>
      <div class="container d-flex justify-content-between px-4 py-2">
        <div class="section-name p-1" id="location-results-section-name">
            {{$t("components.places_list.all_locations")}} ({{placesCount}})
        </div>
        <button class="btn btn-outline-dark btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
          {{$t("components.places_list.filters")}}
        </button>
      </div>
      <div class="collapse" id="collapseExample">
        <div class="px-2 py-1">
          <FiltersSelector />
        </div>
      </div>
      <div class="results">
          <PlacesItem 
              v-for="(place, index) in placesList" 
            :title="place.title"
            :address1="place.address1"
            :address2="place.address2"
            :index="index"
            :key="index"
            :hilighted="index == highlighted"
            :id="`place${index}`"
            @click="() => hilight(index)"
            @details="() => showDetails(index)"
          />
      </div>
    </div>
    <div v-else id="locations-panel-details">
      <PlaceDetails :location="currentPlace" @back="() => hideDetails()"/>
    </div>
  </div>
</template>

<script>
import { usePlacesListStore } from '@/stores/PlacesListStore';
import { storeToRefs } from 'pinia';
import PlacesItem from './PlacesItem.vue';
import PlaceDetails from './PlaceDetails.vue';
import FiltersSelector from './FiltersSelector.vue';

export default {
  name: 'PlacesList',
  components: { PlacesItem, PlaceDetails, FiltersSelector },
    setup() {
        const placesListStore = usePlacesListStore()
        const { 
          placesList, 
          highlighted,
          placesCount,
          scrollToLocation,
          details,
          currentPlace,
          search
          } = storeToRefs(usePlacesListStore());

        return {
          placesList,
          showDetails: placesListStore.showDetails,
          hideDetails: placesListStore.hideDetails,
          unhighlight: placesListStore.unhighlight,
          filterMapLocations: placesListStore.filterMapLocations,
          highlighted,
          placesCount,
          scrollToLocation,
          details,
          currentPlace,
          search,
          mountAutocomplete: placesListStore.mountAutocomplete,
          hilight: placesListStore.hilight
        }
    },
    updated() {
      if(this.scrollToLocation){
        if(!this.details && this.highlighted && this.placesList.length > 0){
          let h = this.highlighted;
          let offset = document.getElementById(`place${h}`).offsetTop;
          document.getElementById('locations-panel').scrollTop = offset;
        }
      }
    },
    beforeMount(){
      if(this.details){
        this.hideDetails();
      }
    },
    mounted(){
      this.mountAutocomplete(this.$refs['location-search-input'], this.$refs['location-search-button']);
      if(this.scrollToLocation){
        if(!this.details && this.highlighted && this.placesList.length > 0){
          let h = this.highlighted;
          let offset = document.getElementById(`place${h}`).offsetTop;
          document.getElementById('locations-panel').scrollTop = offset;
        }
      }
    }
}
</script>

<style scoped>
header {
  padding: 1.4em 1.4em 0 1.4em;
}

button.select-location {
  margin-bottom: 0.6em;
  text-align: left;
}

.search-input {
  width: 100%;
  margin-top: 0.8em;
  position: relative;
}

.search-input input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3em;
  height: 2.2em;
  box-sizing: border-box;
  padding: 0 2.5em 0 1em;
  font-size: 1em;
}

.search-input-overlay {
  position: absolute;
}

.search-input-overlay.search {
  right: 2px;
  top: 2px;
  bottom: 2px;
  width: 2.4em;
}

.search-input-overlay.search button {
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
  color: black;
  background: transparent;
}

.search-input-overlay.search .icon {
  margin-top: 0.05em;
  vertical-align: top;
}

.section-name {
  font-weight: 500;
  font-size: 0.9em;
}



#location-results-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#locations-panel-details {
  padding: 1.4em;
  box-sizing: border-box;
  /* display: none; */
}

#locations-panel {
  max-height: 100%;
  background: white;
  padding: 0.5em;
  box-sizing: border-box;
  overflow-y: scroll;
}

h3.search-title {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
}

h3.search-title > img {
  vertical-align: bottom;
  margin-top: -1em;
}
</style>