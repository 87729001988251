<template>
    <div class="p-4 fw-bold shadow recipe-main rounded container my-4">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="row text-center justify-content-center">
                    <h2 class="fw-bold">{{ recipe.title }}</h2>
                </div>
                <hr/>
                <div class="row">
                    <h3>{{ recipe.altTitle }}</h3>
                </div>
                <div class="row">
                    <p>{{ recipe.description }}</p>
                </div>
                <hr/>
                <div class="row">
                    <div class="row">
                        <h4>{{ $t('components.recipe.ingredients') }}</h4>
                    </div>
                    <div class="col-md-6 col-12">
                        <ul >
                            <li  v-for="(ing, index) in recipe.ingridients" :key="index" aria-current="true">{{ ing }}</li>
                        </ul>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <h4>{{ $t('components.recipe.recipe') }}</h4>
                </div>
                
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-12">
                    <iframe  width="100%" style="height: 50vh;" :src="recipe.yt.link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useRecipesListStore } from '@/stores/RecipesStore';
import { useRoute } from 'vue-router'
export default {
    setup(){
        const recipesListStore = useRecipesListStore()
        const route = useRoute()
        const id = route.params.id
        return {
            recipe: recipesListStore.getRecipe(id)
        }
    }
}
</script>
<style>
.recipe-main {
    background-color: rgba(248, 249, 250, 0.7)
    /* #f8f9fa;
    opacity: 0.5; */
}
</style>