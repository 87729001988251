<template>
  <div id="locator-details-tmpl" >
      <button class="back-button" @click="$emit('back', 0)">
        <img class="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/arrow_back/v11/24px.svg" alt=""/>
        Powrót
      </button>
      <header>
        <div class="banner">
          <svg width="23" height="32" viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9976 11.5003C22.9976 13.2137 22.7083 14.9123 21.8025 16.7056C18.6321 22.9832 12.7449 24.3314 12.2758 30.7085C12.2448 31.1294 11.9286 31.4744 11.4973 31.4744C11.0689 31.4744 10.7527 31.1294 10.7218 30.7085C10.2527 24.3314 4.3655 22.9832 1.19504 16.7056C0.289306 14.9123 0 13.2137 0 11.5003C0 5.13275 5.14557 0 11.5003 0C17.852 0 22.9976 5.13275 22.9976 11.5003Z" fill="#4285F4"/>
            <path fill-rule="evenodd" clip-rule="evenodd" transform="translate(5.5,5.5)" d="M6 8.84091L9.708 11L8.724 6.92961L12 4.19158L7.6856 3.83881L6 0L4.3144 3.83881L0 4.19158L3.276 6.92961L2.292 11L6 8.84091Z" fill="#FBE15C"/>
          </svg>
        </div>
        <h4>{{location.title}}</h4>
      </header>
      <div class="address">
        {{location.address1}}<br>
        {{location.address2}}
      </div>
      <div class="atmosphere">
        <template v-if="place.rating">
          <span class="star-rating-numeric">{{place.rating}}</span>
          <span>
            <template v-for="(_, index) in place.fullStarIcons" :key="index">

              <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star/v15/24px.svg"
                   alt="gwiazdka" class="star-icon"/>
            </template>
            <template v-for="(_, index) in place.halfStarIcons" :key="index">
              <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star_half/v17/24px.svg"
                   alt="pół gwiazdki" class="star-icon"/>
            </template>
            <template v-for="(_, index) in place.emptyStarIcons" :key="index">
              <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star_outline/v9/24px.svg"
                   alt="bez gwiazdki" class="star-icon"/>
            </template>
          </span>
        </template>
        <template v-if="place.user_ratings_total">
          <a :href="place.url" target="_blank">{{place.user_ratings_total}} oceny</a>
        </template>
        <template v-else>
          <a href="https://www.google.com/maps/search/?api=1&query={{location.coords.lat}},{{location.coords.lng}}" target="_blank">Zobacz na mapach google</a>
        </template>
        <template v-if="place.price_level">
          &bull;
          <span class="price-dollars">
            <template v-for="(_, index) in place.dollarSigns" :key="index" >
              $
            </template>
          </span>
        </template>
      </div>
      <template v-if="place.opening_hours" >
        <hr/>
        <div class="hours contact">
          <img src="https://fonts.gstatic.com/s/i/googlematerialicons/schedule/v12/24px.svg"
               alt="Opening hours" class="icon"/>
          <div class="right">
            <template v-for="(op, index) in place.openingHoursSummary" :key="index">
              <div>
                <span class="weekday">{{op.days}}</span>
                <span class="hours">{{op.hours}}</span>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-if="place.website">
        <div class="website contact">
          <img src="https://fonts.gstatic.com/s/i/googlematerialicons/public/v10/24px.svg"
               alt="Website" class="icon"/>
          <div class="right">
            <a href="{{place.website}}" target="_blank">{{place.websiteDomain}}</a>
          </div>
        </div>
      </template>
      <template v-if="place.formatted_phone_number">
        <div class="phone contact">
          <img src="https://fonts.gstatic.com/s/i/googlematerialicons/phone/v10/24px.svg"
               alt="Phone number" class="icon"/>
          <div class="right">
            {{place.formatted_phone_number}}
          </div>
        </div>
      </template>
      <template v-if="place.html_attributions && place.html_attributions.length">
        <template v-for="(atr, index) in place.html_attributions" :key="index">
          <p class="attribution">{{atr}}</p>
        </template>
      </template>
      <template v-if="location.custom.description">
        <hr/>
        <div class="description">
            <p>{{location.custom.description}}</p>
        </div>
      </template>
      <template v-if="location.custom.yt">
        <hr/>
        <div class="links">
          <template v-for="(yt, index) in location.custom.yt" :key="index">
            <div  class="link">
              <a :href="yt.link" target="_blank"><img :src="images.yt">
                <p>{{yt.name}}</p>
              </a>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div>
      <!-- location-ad -->
      <ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-2917376840034915"
          data-ad-slot="6392252950"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
    </div>
</template>

<script>
export default {
  name: 'PlaceDetails',
  props: {
      location: Object
  },
  setup (props) {
    return {
      place: props.location.place,
      images: {
        yt: require('../assets/icons/youtube_social_icon_red.png')
      }
    }
  },
  updated () {
    var adsbygoogle = window.adsbygoogle || []
    adsbygoogle.push({});
  }
}
</script>

<style scoped>
.back-button {
  font-size: 1em;
  font-weight: 500;
  color: #7e7efd;
  display: block;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
}

.back-button .icon {
  width: 20px;
  height: 20px;
  vertical-align: bottom;

  /* Match link color #7e7efd */
  filter: invert(65%) sepia(87%) saturate(4695%) hue-rotate(217deg) brightness(105%) contrast(98%);
}

header {
  text-align: center;
}

.banner {
  margin-top: 1em;
}

h4 {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.address {
  text-align: center;
  font-size: 0.9em;
  margin-top: 1.3em;
}

.atmosphere {
  text-align: center;
  font-size: 0.9em;
  margin: 0.8em 0;
}

.star-rating-numeric {
  color: #555;
}

.star-icon {
  width: 1.2em;
  height: 1.2em;
  margin-right: -0.3em;
  margin-top: -0.08em;
  vertical-align: top;
  filter: invert(88%) sepia(60%) saturate(2073%) hue-rotate(318deg) brightness(93%) contrast(104%);
}

.star-icon:last-of-type {
  margin-right: 0.2em;
}

.price-dollars {
  color: #555;
}

hr {
  height: 1px;
  color: rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
  margin-bottom: 1em;
}


.contact {
  font-size: 0.9em;
  margin: 0.8em 0;
  display: flex;
  align-items: center;
}

.contact .icon {
  flex: 0 0 auto;
  width: 1.5em;
  height: 1.5em;
}


.contact .right {
  padding: 0.1em 0 0 1em;
}

a {
  text-decoration: none;
  color: #7e7efd;
}

.hours .weekday {
  display: inline-block;
  width: 5em;
}

.website a {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  max-width: 16em;
  text-overflow: ellipsis;
}

p.attribution {
  color: #777;
  margin: 0;
  font-size: 0.8em;
  font-style: italic;
}

.link img {
  padding: 5px;
  float: left;
}

.link p {
  overflow: hidden;
}

.description p {
  text-align: center;
}

</style>