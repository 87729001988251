import { Loader } from "@googlemaps/js-api-loader"

const initArray = function(arraySize) {
    const array = [];
    while (array.length < arraySize) {
      array.push(0);
    }
    return array;
  };

export const types = {
    'restaurant': {"name": "Jedzenie", "icon":require("@/assets/icons/restaurant.png")},
    'place': {"name": "Inne", "icon": require("@/assets/icons/dot.png")},
    'hotel': {"name": "Noclegi", "icon":require("@/assets/icons/lodging.png")},
    'drink': {"name": "Alkohole", "icon":require("@/assets/icons/bar.png")},
    'castle': {"name": "Zabytki", "icon":require("@/assets/icons/historic.png")},
    'museum': {"name": "Muzea", "icon":require("@/assets/icons/museum.png")},
    'tea': {"name": "Kawa/Herbata", "icon":require("@/assets/icons/cafe.png")}
}


export default class MapService {

    google = null;
    map = null;
    loaded = false;
    markers = null;
    detailsService = null;
    searchLocationMarker = false;


    constructor(mapConfig) {
        this.mapConfig = mapConfig
        this.loading = new Loader({
            apiKey: "AIzaSyDSRS1qlRkeSRHCIUQRdspZqDc7fW9qo4E",
            libraries: ["places"],
        }).load();

        this.loading.then((google) => {
            this.google = google;
            this.loaded = true;
        });

    }

    isInit(){
        return this.loaded && this.google != null && this.detailsService != null && this.map != null && this.markers != null;
    }

    async mountMap (el, clickCallback) {

        this.mapMountPromise = new Promise( (resolve) =>{
                this.loading.then(() =>{
                    if (this.loaded == true){
                        //config should be moved to constructor
                        this.map = new this.google.maps.Map(el, { "center": { "lat": 48.14, "lng": 17.10 }, "fullscreenControl": true, "mapTypeControl": false, "streetViewControl": false, "zoom": 6, "zoomControl": true, "maxZoom": 17 });
                        this.detailsService = new this.google.maps.places.PlacesService(this.map);
                        this.clickCallback = clickCallback;
                    }
                    resolve();
                });  
            });
        
        return this.mapMountPromise;
        
    }

    geocodeSearch(query){
        if (!query) {
            return;
        }

        if (this.geocodeCache.has(query)) {
            this.handleResult(this.geocodeCache.get(query));
            return;
        }
        const request = {address: query, bounds: this.map.getBounds()};
        this.geocoder.geocode(request, (results, status) => this.handleGeocode(results, status, query));
    }
    
    handleResult (geocodeResult) {
        this.inputEl.value = geocodeResult.formatted_address;
        this.updateSearchLocation(geocodeResult.formatted_address, geocodeResult.geometry.location);
    }

    handleGeocode(results, status, query) {
        if (status === 'OK') {
            if (results.length > 0) {
              const result = results[0];
              this.geocodeCache.set(query, result);
              this.handleResult(result);
            }
          }
    }

    updateSearchLocation(address, location) {
        if (this.searchLocationMarker) {
            //TODO Does not work
            this.searchLocationMarker.setVisible(false);
            this.searchLocationMarker.setMap(null);
        }if (!location) {
            this.searchLocation = null;
            return;
        }
        this.searchLocation = {'address': address, 'location': location};
        this.searchLocationMarker = new this.google.maps.Marker({
            position: location,
            map: this.map,
            title: 'Moja lokacja',
            icon: {
                path: this.google.maps.SymbolPath.CIRCLE,
                scale: 40,
                fillColor: '#3367D6',
                fillOpacity: 0.3,
                strokeOpacity: 0,
            }
        });

        this.map.setCenter(location)
        // this.map.setZoom(10); //zoom on search
    }    

    autocompletePlaceChanged(){
        const placeResult = this.autocomplete.getPlace();
        if (!placeResult.geometry) {
            // Hitting 'Enter' without selecting a suggestion will result in a
            // placeResult with only the text input value as the 'name' field.
            this.geocodeSearch(placeResult.name);
            return;
        }
        this.updateSearchLocation( placeResult.formatted_address, placeResult.geometry.location);
    }

    async mountAutocomplete(inputEl, buttonEl) {

        this.inputEl = inputEl;
        this.buttonEl = buttonEl;

       

        return this.loading.then(() =>{
            this.mapMountPromise.then(() => {

                if (this.loaded == true){

                    this.geocodeCache = new Map();
                    this.geocoder = new this.google.maps.Geocoder();
                    this.autocomplete = new this.google.maps.places.Autocomplete(inputEl, {
                            types: ['geocode'],
                            fields: ['place_id', 'formatted_address', 'geometry.location']
                        });
                    this.autocomplete.bindTo('bounds', this.map);
                    this.autocomplete.addListener('place_changed',() => this.autocompletePlaceChanged());
                
                    this.buttonEl.addEventListener('click', () => this.geocodeSearch(this.inputEl.value.trim()));
                }
            });
        });
        

    }

    renderLocations (locationsList) {
        
        if (this.map !== null){

            if(this.markers !== null){
                this.markers.map((mark) => {
                    mark.setMap(null);
                })
                this.markers = [];
                this.markers = null;
            }
            
            this.markers = locationsList.map((location, index) => {
                const typeInfo = types[location.type] ?? {"icon": require("@/assets/icons/dot.png")}

                const marker = new this.google.maps.Marker({
                    // animation: this.google.maps.Animation.DROP,
                    position: location.coords,
                    map: this.map,
                    title: location.title,
                    icon: typeInfo.icon
                });

                marker.addListener('click', () => this.clickCallback(index));

                return marker;
            })
        }

        

        this.updateBounds();
    }

    updateBounds () {
        if(this.markers !== null && this.markers.length > 0){
            const bounds = new this.google.maps.LatLngBounds();
            for (let i = 0; i < this.markers.length; i++) {
                bounds.extend(this.markers[i].getPosition());
            }
            this.map.fitBounds(bounds);
        }
    }

    panTo (coords) {
        this.map.panTo(coords);
    }

    zoom (lvl) {
        this.map.setZoom(lvl);
    }




    async getPlaceDetails (id) {
        if(this.detailsService != null){
            const request = {
                placeId: id,
                fields: [
                  'formatted_phone_number', 'website', 'opening_hours', 'url',
                  'utc_offset_minutes', 'price_level', 'rating', 'user_ratings_total'
                    ]
                };
            
            return new Promise( (resolve) => {
                var okStatus = this.google.maps.places.PlacesServiceStatus.OK;
                this.detailsService.getDetails(request, function(place, status) {
                    if (status == okStatus) {
                        if (place.opening_hours) {
                          const daysHours =
                              place.opening_hours.weekday_text.map(e => e.split(new RegExp("\\:\\s+"))) // TODO
                                  .map(e => ({'days': e[0].substr(0, 3), 'hours': e[1]}));
        
                          for (let i = 1; i < daysHours.length; i++) {
                            if (daysHours[i - 1].hours === daysHours[i].hours) {
                              if (daysHours[i - 1].days.indexOf('-') !== -1) {
                                daysHours[i - 1].days =
                                    daysHours[i - 1].days.replace(/\w+$/, daysHours[i].days);
                              } else {
                                daysHours[i - 1].days += ' - ' + daysHours[i].days;
                              }
                              daysHours.splice(i--, 1);
                            }
                          }
                          place.openingHoursSummary = daysHours;
                        }
                        if (place.rating) {
                          const starsOutOfTen = Math.round(2 * place.rating);
                          const fullStars = Math.floor(starsOutOfTen / 2);
                          const halfStars = fullStars !== starsOutOfTen / 2 ? 1 : 0;
                          const emptyStars = 5 - fullStars - halfStars;
        
                          // Express stars as arrays to make iterating in Handlebars easy.
                          place.fullStarIcons = initArray(fullStars);
                          place.halfStarIcons = initArray(halfStars);
                          place.emptyStarIcons = initArray(emptyStars);
                        }
                        if (place.price_level) {
                          place.dollarSigns = initArray(place.price_level);
                        }
                        if (place.website) {
                          const url = new URL(place.website);
                          place.websiteDomain = url.hostname;
                        }
                        // context.place = place;
                        resolve(place);
                    }else{
                        resolve({});
                    }
                })
            });
        }   
    }
}