export default {
  "navbar": {
    "brand": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa Podróżnika"])}
    },
    "locations": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
      "recipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipes"])}
    }
  },
  "components": {
    "places_list": {
      "locations_near_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find locations near you"])},
      "input_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "input_addres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your address"])},
      "all_locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All locations"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])}
    },
    "places_item": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
    },
    "filters_selector": {
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep"])},
      "drink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drinks"])},
      "castle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monuments"])},
      "museum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Museums"])},
      "tea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffee/Tea"])}
    },
    "about_section": {
      "places_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places visited by Robert Makłowicz"])},
      "places_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can find all the locations visited by Mr Robrert Makłowicz during his jurneys; countless geographic regions, restaurants, local attractions and interesting monuments."])},
      "whois_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is Robert Makłowicz?"])},
      "whois_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr Robert Makłowicz is a food critic, journalist, writer, and traveler known all over Poland. Years spent in journey while making a popular TV show gave him a chance to build tremendous volume of knowledge about the worlds cultures, traditions and history. The travels shaped his instinct which always leads him towards heavenly places full of delicious food. Thanks to his experience Mr Robert has released many culinary-historical books like „C.K. Kuchnia\", „Cafe Museum\" and „Dalmacja\"."])},
      "travels_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travels"])},
      "travels_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Mr Robert runs a culinary-historical youtube channel on which he routinely presents interesting places in Poland and outside of its borders. Special focus is always put on exploration of local cuisine. Each episode of the series is filled with beautiful locations and interesting stories, as well as delicious dishes, some of which are prepared by Mr Robert himself. So far he has managed to visit Croatia with a particular focus on Dalmatia and Baranja regions, Greater Poland, Beskid, Mazovia, Pomerania, Podlasie, Sweden, Austria, South Tyrol and Bosnia. He also routinely presents cultures from distant corners of the world like Kyrgyzstan, Tatar steppes and Japan."])},
      "travels_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furthermore he regularly hosts on his channel interesting guests who share have extensive knowledge of gastronomy, tourism and recreation or have contributed to the conservation of the cultural heritage found in the visited locations. Additionally in his videos Mr Robert presents local attractions like diving in Adriatic Sea or dog sled riding over a frozen lake."])},
      "travels_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information about Mr Robert Makłowicz as well as his recipes can be found on his website "])},
      "freetime_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan your free time"])},
      "freetime_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website can help you plan your travels so that they are full  of interesting attraction, beautiful landscapes and great food. No matter if the journey ahead is long vacation or a getaway for the weekend. The map can also help you find out if Mr Robert has discovered any local gems near location of your interest. Details collected for each cataloged place allow you to go directly to the youtube video and timestamp where it is presented by Mr Robert."])},
      "freetime_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In his show Mr Robert Makłowicz visits many places, some of which are not open to the public. He also frequently shows locations that are difficult to narrow down to single geographic location like cites that are presented in a form of a long walk filled with interesting observations about the encountered surroundings. In result some specific places can be missing from the gathered data or be bundled together for example as coordinates of specific city."])},
      "freetime_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is open and everyone can use it free of charge. Resources needed to cover the costs of hosting are generated through the advertisements. We kindly ask you to disable your ad-block solution. We do our best to update the locations regularly however sometimes there can be a delay."])},
      "freetime_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't forget to share if you enjoyed the website. Any comments and feedback is welcome under the email "])},
      "freetime_text_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling is an art that Mr Robert has mastered in every aspect so lets all learn from him. Ajde!"])},
      "recipes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipes"])},
      "recipes_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr Robert in almost each episode of his show presents unique dishes, frequently ispired by the gastronomic landscape of the visited place. On our website you can find a catalogue of recipes by Mr Robert Makłowicz along with the list of ingredients and the reference to the youtube video that shows how to prepare the dish. Cooking a meal from the distant corner of the world can help you travel without leaving your home."])}
    },
    "recipes_list": {
      "input_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
    },
    "recipe": {
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
      "recipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipe"])}
    },
    "adblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is available free of charge. All costs are covered by the ad revenue. Please consider disabling your adblock solution."])},
    "share_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa Makłowicza"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site let's you find amazing places visited by Mr. Robert Makłowicz."])}
    }
  }
}