<template>
  <div class="row px-2">
    <div class="col px-2">
      <button>
        <router-link :to="'/pl/' + $route.name">
          <img
            class="rounded-flag shadow"
            :src="require('@/assets/icons/pl.svg')"
            alt="pl"
            height="37.5"
            width="37.5"
          />
        </router-link>
      </button>
    </div>
    <div class="col px-2">
      <button>
        <router-link :to="'/en/' + $route.name">
          <img
            class="rounded-flag shadow"
            :src="require('@/assets/icons/gb.svg')"
            alt="en"
            height="37.5"
            width="37.5"
          />
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: "LangSelector",
};
</script>

<style>
.rounded-flag {
  border-radius: 50%;
}
</style>
