<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'MainView',
    setup() {
        return {
        }
    }
}
</script>

<style>

</style>