<template>
  <div class="p-4 list-main fw-bold shadow rounded container my-4">
    <div class="row">
      <div class="input-group mb-3">
        <input type="text" class="form-control" v-model="search"  :placeholder="$t('components.recipes_list.input_search')" aria-label="Recipient's username" aria-describedby="button-addon2">
        <button class="btn btn-outline-secondary" type="button" id="button-addon2"><img class="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/search/v11/24px.svg"
                  alt="Search" /></button>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12" v-for="(recipe, index) in recipesList" :key="index" >
        <router-link style="text-decoration: none; color: inherit;" :to="'/' + $route.params.lang + `/recipe/${recipe.orginIndex}`" >
          <PecipeListItem :recipe="recipe"/>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { useRecipesListStore } from '@/stores/RecipesStore';
import { storeToRefs } from 'pinia';
import PecipeListItem from './RecipeListItem.vue'

export default {
    components: {
        PecipeListItem
    },
    setup(){
        // const recipesListStore = useRecipesListStore()
        const { 
            recipesList,
            search
          } = storeToRefs(useRecipesListStore());

        return {
          recipesList,
          search
        }
    }
}
</script>
<style>
.list-main {
    background-color: rgba(248, 249, 250, 0.6)
    /* #f8f9fa;
    opacity: 0.5; */
}

.search-input {
  width: 100%;
  /* margin-top: 0.8em;
  position: relative; */
}

.search-input input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3em;
  height: 2.2em;
  box-sizing: border-box;
  padding: 4px 2.5em 0 1em;
  font-size: 1em;
  line-height: normal;
}

.search-input-overlay {
  position: absolute;
}

.search-input-overlay.search {
  right: 2px;
  top: 2px;
  bottom: 2px;
  width: 2.4em;
}

.search-input-overlay.search button {
  width: 100%;
  height: 100%;
  border-radius: 0.2em;
  color: black;
  background: transparent;
}

.search-input-overlay.search .icon {
  margin-top: 0.05em;
  vertical-align: top;
  padding-right: 10px;
}

</style>