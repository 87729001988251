<template>
  <div class="custom-modal">
    <div class="close-button fw-bold text-light">
      <button>
        <h2>X</h2>
      </button>
    </div>
    <div class="col-10 col-md-6 bg-light rounded">
      <div class="row text-center p-3">
        <div class="col-12content-align-center">
          <img :src="require('@/assets/adblock.png')" alt="adblock" height="100" width="100" />
        </div>
      </div>
      <div class="row p-3 text-center">
        <h4>{{$t('components.adblock')}}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdblockModal"
}
</script>

<style scoped>
.custom-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0, 0, 0, .7);
    z-index: 100000;
    align-items: center;
    display: flex;
    justify-content: center;
}

.centered{
    height: 100%;
    width: 100%;
    
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
}
</style>