<template>
    <div class="location-result" :class="{selected: hilighted}" >
        <button class="select-location" >
            <h4 class="name">{{title}}</h4>
        </button>
        <div class="address">{{address1}}<br>{{address2}}</div>
        <button @click="$emit('details', index)" class="details-button">
            {{$t("components.places_item.details")}}
        </button>
    </div>
</template>

<script>
export default {
  name: 'PlacesItem',
  props: {
      title: String,
      index: Number,
      address1: String,
      address2: String,
      hilighted: Boolean
  }
}
</script>

<style>

.location-result {
  position: relative;
  padding: 0.8em 3.5em 0.8em 1.4em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-align: left;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  font-size: inherit;
  cursor: pointer;
}

.select-location {
  margin-bottom: 0.6em;
  text-align: left;
}

h4.name {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
}

.selected {
  outline: 2px solid #4285f4;
}

.address {
  font-size: 0.9em;
  margin-bottom: 0.5em;
}

button.select-location {
  margin-bottom: 0.6em;
  text-align: left;
}

.location-result:first-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.location-result:last-of-type {
  border-bottom: none;
}

.details-button {
  font-size: 0.9em;
  color: #7e7efd;
}

.selected {
  outline: 2px solid #4285f4;
}

</style>