<template>
    <RecipeSection/>
</template>
<script>
import RecipeSection from '@/components/recipe/RecipeSection.vue';

export default {
    name: 'RecipePage',
    components: { 
        RecipeSection
    },
}
</script>
<style>

</style>