import { defineStore } from 'pinia';
import recipesList from '@/data/recipes.json';

export const useRecipesListStore = defineStore('RecipesList', {
    state: () => {
  
      return {
        recipesListUnfiltered: recipesList.map((recipe, index) => { recipe['orginIndex'] = index; return recipe; }),
        search: "",
      }
    },
    actions: {
      getRecipe(id){
        return this.recipesListUnfiltered[id];
      },
    },
    getters: {
      recipesList() {
        var filter = this.search.toLowerCase();
        if(filter.length >= 3){
          return this.recipesListUnfiltered.filter((recipe) => {
            if(recipe.title.toLowerCase().includes(filter)){
              return true;
            }
            if(recipe.altTitle.toLowerCase().includes(filter)){
              return true;
            }
            if(recipe.description.toLowerCase().includes(filter)){
              return true;
            }
            for(const ing in recipe.ingridients){
              if(ing.toLowerCase().includes(filter)){
                return true;
              }
            }
            if(recipe.yt.title.toLowerCase().includes(filter)){
              return true;
            }
            return false;
          });
        }else{
          return this.recipesListUnfiltered;
        }
      }
    }
  })
  