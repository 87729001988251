import { defineStore } from 'pinia';
import placesList from '@/data/places.json';
import MapService from '@/services/MapService';
import { types } from '@/services/MapService';

let filtersObject = {};

Object.keys(types).forEach(key => {
  filtersObject[key] = true;
});

export const usePlacesListStore = defineStore('PlacesList', {
  state: () => {

    return {
        currentPlace: null,
        filters: filtersObject,
        placesListUnfiltered: placesList.map((place, index) => { place['orginIndex'] = index; return place; }),
        search: "",
        details: false,
        highlighted: null,
        mapService: new MapService({}),
        scrollToLocation: true
    }
  },
  actions: {
    hilight(id){
      this.highlighted = id;
      this.scrollToLocation = false;
      this.mapService.panTo(this.placesList[id].coords);
    },
    hilightNoPan(id){
      this.scrollToLocation = true;
      this.highlighted = id;
      if(this.details){
        var currentPlace = this.placesList[id];
        
        if(currentPlace.placeId){
          this.mapService.getPlaceDetails(currentPlace.placeId).then((place) => {
            currentPlace.place = place;
            this.currentPlace = currentPlace;
            this.details = true;
          });
        }else{
          currentPlace.place = {};
          this.currentPlace = currentPlace;
          this.details = true;
        }
      }
    },
    unhighlight(){
      this.highlighted = null;
    },    
    showDetails(id){
      var currentPlace = this.placesList[id];
      if(currentPlace.placeId){
        this.mapService.getPlaceDetails(currentPlace.placeId).then((place) => {
          currentPlace.place = place;
          this.currentPlace = currentPlace;
          this.details = true;
        });
      }else{
        currentPlace.place = {};
        this.currentPlace = currentPlace;
        this.details = true;
      }
      this.mapService.zoom(15);
    },
    hideDetails(){
      this.scrollToLocation = true;
      this.details = false;
    },
    renderLocations(){
      this.mapService.renderLocations(this.placesList)
    },
    mountMap(el) {
      this.mapService.mountMap(el, this.hilightNoPan).then(() => {
        this.mapService.updateBounds();
        this.mapService.renderLocations(this.placesList);
      });
    },
    mountAutocomplete(inputEl, buttonEl) {
      this.mapService.mountAutocomplete(inputEl, buttonEl);
    },
    initLocation(id){
      if(id){
        var idInt = parseInt(id);
        if(idInt <= this.placesListUnfiltered.length){
          var interval = setInterval(() => {
            if(this.mapService.isInit()){
                clearInterval(interval);
                this.hilight(parseInt(id));
                this.showDetails(parseInt(id));
              }
          },
          5*100)
        }
      }
    },
    updateBound(){
      this.mapService.updateBounds();
    },
    filterMapLocations(){
      this.mapService.renderLocations(this.placesList);
    }
  },
  getters: {
    placesCount(){
      return this.placesList.length;
    },
    placesList() {

      var typeFiltered = this.placesListUnfiltered.filter((place) => {
        return this.filters[place.type];
      });

      var filter = this.search.toLowerCase();
      if(filter.length >= 3){
        return typeFiltered.filter((place) => {
          if(place.title.toLowerCase().includes(filter)){
            return true;
          }
          if(place.custom.description ){
            if(place.custom.description.toLowerCase().includes(filter)){
              return true;
            }
          }
          for (const yt of place.custom.yt) {
            if(yt.name.toLowerCase().includes(filter)){
              return true;
            }
            if(yt.link.toLowerCase().includes(filter)){
              return true;
            }
          }
          return false;
        });
      }else{
        return typeFiltered;
      }
    }
  }
})
