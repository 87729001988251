<template>
  <AboutSection/>
</template>

<script>
import AboutSection from '../components/AboutSection.vue'
export default {
  name: 'AboutPage',
  components: { AboutSection },

}
</script>

<style>
</style>